import { useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';

import useQueryParams from '@app/hooks/useQueryParams';
import { routeTo } from '@app/helpers/RoutesHelper';
import { Customer, CustomerVehicle, FollowUpTaskStatus } from '@app/crud/customers/customer.type';
import { showDetailEvent } from '@app/pages/customers/Helpers/Events';
import useActionClient from '@app/pages/customers/Hooks/useActionClient';
import useLead from '@app/pages/customers/Hooks/useLead';
import ROUTES from '@app/router/Routes';
import { actions } from '@app/store/modal/modal.store';
import TakeLeadClient from '@app/crud/action/take-lead';
import { useAppSelector } from '@app/hooks';

interface Props {
    customer: Customer;
    activity: {
        data: CustomerVehicle[];
    };
}

const ActivityLead = ({ customer, activity }: Props): null => {
    const { userData } = useAppSelector((state) => state.auth);
    // avoid click on lead row on useEffect when already clicked
    const leadOpened = useRef(false);
    const queryParams = useQueryParams();
    const history = useHistory();
    const location = useLocation();
    const Intl = useIntl();
    const lead = useLead(queryParams?.leadId);
    const dispatch = useDispatch();

    const [caller, loading] = useActionClient(new TakeLeadClient(), {
        notifySucess: Intl.formatMessage({ id: 'LEADS_ONLINE.TAKE_LEAD.SUCCESS' }),
        notifyFail: Intl.formatMessage({ id: 'LEADS_ONLINE.TAKE_LEAD.FAILED' }),
    });

    const takeLead = () => {
        caller({
            lead: lead?.id,
        }).catch((reason) => {
            console.error(reason);
        });
    };

    useEffect(() => {
        if (!lead?.flags) return;

        const { flags } = lead;
        const modalTriggerStatus = [FollowUpTaskStatus.CLOSED, FollowUpTaskStatus.OPEN];
        const followUpTaskStatus = flags.find((flag: any) => modalTriggerStatus.includes(flag));

        if (followUpTaskStatus) {
            dispatch(
                actions.modalChange('follow_up_task', {
                    leadId: lead?.id,
                }),
            );
        }
    }, [lead]);

    // Handle activity lead
    useEffect(() => {
        if (!loading) {
            if (!customer?.leads || !lead || !userData?.id) return;

            if (queryParams?.action === 'take') {
                if (lead?.owner?.id && userData?.id && lead?.owner?.id !== userData?.id) {
                    Swal.fire({
                        text: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.TAKE_LEAD.TEXT' }),
                        icon: 'error',
                        customClass: {
                            confirmButton: 'btn mr-3 btn-primary',
                        },
                        buttonsStyling: false,
                    }).then(() => {
                        history.replace(routeTo(ROUTES.DASHBOARD.PATH));
                    });
                } else {
                    takeLead();
                }
                history.replace(location.pathname);
            } else if (queryParams?.action === 'viewLead' && lead?.id) {
                const showDetailButton = document.querySelector(`#event-all-${lead?.id}`);

                if (showDetailButton && activity?.data?.length > 0 && !leadOpened.current) {
                    showDetailEvent(showDetailButton);
                    leadOpened.current = true;
                }
            }
        }
    }, [queryParams, customer, activity, location, userData, history, loading]);

    return null;
};

export default ActivityLead;

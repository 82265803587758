export const eventSlug = {
    SC_RENEWAL: 'securycar_renewal',
    SC_DISASTER: 'securycar_disaster',
    SC_TERMINATION: 'securycar_termination',
    SC_SERVICE_RESUME: 'securycar_resume',
    SALE_PRODUCT: 'achat',
    PURCHASE_ORDER_PRODUCT: 'achat',
    COMMERCIAL_PROPOSITION_PRODUCT: 'achat',
    TECHNICAL_INTERVENTION: 'prestation_apv',
    TECHNICAL_INTERVENTION_APPOINTMENT: 'prestation_atelier',
    TECHNICAL_INTERVENTION_APPOINTMENT_CANCELED: 'prestation_atelier',
    LEAD_UNQUALIFIED: 'online',
    LEAD_CLOSING: 'online',
    LEAD_FOLLOWUP: 'online',
    LEAD: 'online',
    LEAD_RESUME: 'online',
    AFR_SUBSCRIBER: 'afr_subscriber',
    PLANET_VO: 'planet_vo',
    ORDERS_WEB_RESUME: 'commande_full_web',
    FUNDING: 'captives_fi',
    ICARE: 'icare',
    AUDIT_TSCANS: 'audit_tscans',
    AFTER_SALES_APPOINTMENT: 'mecaplanning',
    AUDIT_RESUME_FB: 'fleetback',
    AUDIT_FOLLOWUP: 'fleetback',
    AUDIT_RESUME_OTHER: 'fleetback',
    WARRANTY_CONTRACT: 'contrat_de_garantie',
    ADICTIZ: 'adictiz',
    OPTEVEN: 'contrat_de_service_opteven',
    COMMERCIAL_PROPOSAL: 'proposition_commerciale',
    TRADE_IN_OFFER: 'offre_commerciale',
};

export const getTypeSlug = (processType, slug, source = '') => {
    const context = (processType && ((source && eventSlug[source]) || eventSlug[processType] || 'other')) || slug;

    // treats all local_vn, local_vo, local_* as local
    if (
        context &&
        context.startsWith('local_') &&
        !['local_achat_cash', 'local_reprise', 'local_apv', 'local_home_charge'].includes(context)
    ) {
        return 'local';
    }

    return context;
};

export const tabs = ['all', 'lead', 'buy', 'apv', 'marketing', 'products', 'other'];
export const tabs_slug = {
    lead: [
        'local',
        'vo',
        'local_vo',
        'vn',
        'local_vn',
        'vd',
        'local_vd',
        'reprise',
        'local_reprise',
        'achat_cash',
        'local_achat_cash',
        'achat_cash_abandon',
        'fid_vo',
        'fid_vn',
        'local_home_charge',
        eventSlug.LEAD,
    ],
    buy: [eventSlug.SALE_PRODUCT, eventSlug.ORDERS_WEB_RESUME, eventSlug.FUNDING],
    apv: [
        'lead_apv',
        'local_apv',
        eventSlug.TECHNICAL_INTERVENTION,
        eventSlug.TECHNICAL_INTERVENTION_APPOINTMENT,
        eventSlug.AFTER_SALES_APPOINTMENT,
        eventSlug.AUDIT_FOLLOWUP,
        eventSlug.AUDIT_TSCANS,
    ],
    marketing: ['newsletter', 'rappel_client', 'marketing_campaign_vo', 'marketing_campaign_vn'],
    products: [
        'financement',
        eventSlug.SC_RENEWAL,
        eventSlug.SC_DISASTER,
        eventSlug.SC_TERMINATION,
        eventSlug.SC_SERVICE_RESUME,
        eventSlug.WARRANTY_CONTRACT,
    ],
    other: ['other', eventSlug.AFR_SUBSCRIBER, eventSlug.PLANET_VO],
};

export const financingInterest = ['Pas du tout intéressé', 'Peu intéressé', 'Moyennement intéressé', 'Très intéressé'];

const sourceList = [
    {
        name: 'Planet VO',
        srcVCU: ['PLANET_VO_PURCHASE_ORDER_PRODUCT'],
        slug: 'planet_vo',
    },
    {
        name: 'T-Scan',
        srcVCU: ['BCS_AUDIT_TSCANS'],
        slug: 'tscan',
    },
    {
        name: 'Fleetback',
        srcVCU: ['BCS_AUDIT', 'BCS_AUDIT_FOLLOWUP', 'BCS_AUDIT_RESUME_FB', 'BCS_AUDIT_RESUME_OTHER'],
        slug: 'fleetback',
    },
    {
        name: 'Salesforce',
        srcVCU: ['SALEFORCE_AUTOSPHERE_SALEFORCE_CONSENT', 'SALEFORCE_DEALERSHIP_SALEFORCE_CONSENT'],
        slug: 'salesforce',
    },
    {
        name: 'Securycar',
        srcVCU: [
            'SECURYCAR_SC_TERMINATION',
            'SECURYCAR_SC_SERVICE_RESUME',
            'SECURYCAR_SC_RENEWAL',
            'SECURYCAR_SC_SERVICE_FOLLOWUP',
            'SECURYCAR_SC_DISASTER',
        ],
        slug: 'securycar',
    },
    {
        name: 'Mecaplanning',
        srcVCU: ['MECAPLANNING_AFTER_SALES_APPOINTMENT'],
        slug: 'mecaplanning',
    },
    {
        name: 'Icare',
        srcVCU: ['ICARE_MAINTENANCE_CONTRACT'],
        slug: 'icare',
    },
    {
        name: 'DMS (Icar)',
        srcVCU: [
            'ICAR_TECHNICAL_INTERVENTION',
            'ICAR_COMMERCIAL_PROPOSITION_PRODUCT',
            'ICAR_PURCHASE_ORDER_PRODUCT',
            'ICAR_SALE_PRODUCT',
        ],
        slug: 'dms_icar',
    },
    {
        name: 'Atelier autosphere',
        srcVCU: [
            'AUTOSPHERE_FR_TECHNICAL_INTERVENTION_APPOINTMENT_CANCELED',
            'AUTOSPHERE_FR_TECHNICAL_INTERVENTION_APPOINTMENT',
        ],
        slug: 'atelier_afr',
    },
    {
        name: 'autosphere',
        srcVCU: [
            'AUTOSPHERE_FR_PART_EXCHANGE_ESTIMATE',
            'AUTOSPHERE_FR_AFR_SUBSCRIBER',
            'AUTOSPHERE_FR_PART_EXCHANGE_ESTIMATE_FOLLOWUP',
            'AUTOSPHERE_FR_PART_EXCHANGE_RESUME',
            'AUTOLEAD_LEAD',
            'AUTOLEAD_LEAD_RESUME',
            'AUTOLEAD_LEAD_CLOSING',
            'AUTOLEAD_LEAD_FOLLOWUP',
            'AUTODELIVERY_ORDERS_WEB',
            'AUTODELIVERY_ORDERS_WEB_FOLLOWUP',
            'AUTODELIVERY_ORDERS_WEB_RESUME',
        ],
        slug: 'afr',
    },
    {
        name: 'Captives Fi',
        srcVCU: ['DATASTORE_FUNDING'],
        slug: 'captives_fi',
    },
    {
        name: 'SIV',
        srcVCU: ['SIV_REGISTRATION'],
        slug: 'siv',
    },
    {
        name: 'DMS (DCSnet)',
        srcVCU: ['DCSNET_SALE_PRODUCT', 'DCSNET_TECHNICAL_INTERVENTION'],
        slug: 'dms_net',
    },
    {
        name: 'Contrat de garantie',
        srcVCU: ['WARRANTY_CONTRACT'],
        slug: 'contrat_de_garantie',
    },
    {
        name: 'Jeu festival Autosphere',
        srcVCU: ['ADICTIZ_JEU_FESTIVAL_AUTOSPHERE'],
        slug: 'adictiz',
    },
    {
        name: 'Contrat de service',
        srcVCU: ['CIRANO_WARRANTY_CONTRACT'],
        slug: 'contrat_de_garantie',
    },
    {
        name: 'Contrat de service',
        srcVCU: ['OPTEVEN_WARRANTY_CONTRACT_OPTEVEN'],
        slug: 'contrat_de_service_opteven',
    },
    {
        name: 'Planet VO',
        srcVCU: ['PLANET_VO2_COMMERCIAL_PROPOSAL'],
        slug: 'proposition_commerciale',
    },
    {
        name: 'Planet VO',
        srcVCU: ['PLANET_VO2_TRADE_IN_OFFER'],
        slug: 'offre_commerciale',
    },
];

export const getCustomerEventSource = (event) => {
    if (event?.leadId || typeof event?.eventId !== 'number') {
        const eventSource = !(event?.srcApplicationName && event?.processType)
            ? event?.leadSourceSlug
            : `${event?.srcApplicationName}_${event?.processType}`;
        const source = sourceList.find((src) => src.srcVCU.includes(eventSource?.toUpperCase()));
        if (source) {
            return source;
        }
    }
    return {
        name: event?.leadSourceName || event?.eventName,
        slug: event?.leadSourceSlug || event?.eventTypeSlug,
        originName: event?.leadSourceSlug !== event?.leadOriginSlug ? event?.leadOriginName : '',
    };
};

export const showDetailEvent = (showDetailButton, scroll = false) => {
    if (!showDetailButton) return;
    const activeTab = showDetailButton.id.split('-')[1];
    const leadId = showDetailButton.id.split('-')[2];
    const container_element = document.getElementById(`event-detail-${activeTab}-${leadId}`);
    if (scroll && container_element) {
        window.scroll({
            top: container_element.getBoundingClientRect().top + window.scrollY - 200,
            left: 0,
            behavior: 'smooth',
        });
    } else {
        showDetailButton.click();
    }
};

export const hideDetailEvent = (showDetailButton) => {
    if (showDetailButton) {
        showDetailButton.click();
    }
};

export const filterCustomerEvents = (events, tab) => {
    // remove event with leadId and eventId emptys
    const noEmptyEvents = events.filter((e) => e?.leadId || e?.eventId);

    if (tab && tab !== 'all') {
        return noEmptyEvents.filter(
            (e) =>
                tabs_slug[tab] &&
                (tabs_slug[tab].includes(e?.leadTypeSlug) ||
                    tabs_slug[tab].includes(getTypeSlug(e?.processType, e?.eventTypeSlug))),
        );
    }
    return events;
};

export const isEventSameAsRow = (data, expandedRow) => {
    const hasSameLeadId = expandedRow?.leadId === data?.leadId;
    const hasSameEventId = expandedRow?.eventId === data?.eventId;
    return hasSameLeadId && hasSameEventId;
};

export function formatNameFromEmail(email) {
    if (!email) {
        return '-';
    }
    const username = email.split('@')[0];

    return username
        .split('.')
        .map((name) => name.charAt(0).toUpperCase() + name.slice(1).toLowerCase())
        .join(' ');
}

import React, { ReactNode } from 'react';
import { useHistory } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';

import { routeTo } from '@app/helpers/RoutesHelper';

type DashboardActivityDealershipCounterProps = {
    message: ReactNode;
    route: string;
    params: NonNullable<unknown>;
    filter: string;
    filterValues: NonNullable<unknown>;
    openNewTab?: boolean;
};

const DashboardActivityDealershipCounter = ({
    message,
    route,
    params,
    filter,
    filterValues,
    openNewTab = true,
}: DashboardActivityDealershipCounterProps) => {
    const { updateFilters, resetAllFilters } = useFilters({
        name: filter,
    });
    const history = useHistory();

    const generateDashboardActivityLink = async () => {
        resetAllFilters();
        updateFilters({
            filters: filterValues,
        });

        (openNewTab ? window.open : history.push)(routeTo(route, params), '_blank');
    };

    return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
        <span onClick={() => generateDashboardActivityLink()} className="pl-2 cursor-pointer">
            <strong className="bullet">&bull; &nbsp;</strong>
            {message}
        </span>
    );
};

export default DashboardActivityDealershipCounter;

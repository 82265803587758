import { CustomerEvent } from '@app/crud/customers/customer.type';

import { formatNameFromEmail } from '@app/pages/customers/Helpers/Events';

interface sourceFunctions {
    [key: string]: (event?: CustomerEvent) => string;
}

const useReferentTemplate = () => {
    const bcsRender = (event: CustomerEvent): string =>
        event?.auditCreatedbyFirstname !== 'Events' || event?.auditCreatedbyLastname !== 'Fleetback'
            ? `${event?.auditCreatedbyFirstname} ${event?.auditCreatedbyLastname}`
            : '-';

    const name = (firstName: string | null | undefined, lastName: string | null | undefined) => {
        const first = firstName || '';
        const last = lastName || '';
        const displayName = first && last ? `${first} ${last}` : first + last;
        return displayName.trim() || '-';
    };

    const referentTemplate: sourceFunctions = {
        DATASTORE_FUNDING: (event) => name(event?.fundingSellerFirstName, event?.fundingSellerLastName),
        ICARE_MAINTENANCE_CONTRACT: (event) =>
            `${formatNameFromEmail(event?.maintenanceContractCodeVendeurContract).toUpperCase() || '-'}`,
        SECURYCAR_SC_SERVICE_RESUME: (event) =>
            name(event?.scServiceResumeSellerFirstName, event?.scServiceResumeSellerLastName),
        SECURYCAR_SC_DISASTER: (event) => event?.scDisasterUserInfo || '',
        MECAPLANNING_AFTER_SALES_APPOINTMENT: () => '-',
        CIRANO_WARRANTY_CONTRACT: (event) => event?.pdvContributor,
        BCS_AUDIT_FOLLOWUP: (event) => bcsRender(event),
        BCS_AUDIT_RESUME_FB: (event) => bcsRender(event),
        BCS_AUDIT_RESUME_OTHER: (event) => bcsRender(event),
        ADICTIZ_JEU_FESTIVAL_AUTOSPHERE: () => '-',
        PLANET_VO2_COMMERCIAL_PROPOSAL: (event) => event?.commercialProposal?.affectedUserLabel || '',
        PLANET_VO2_TRADE_IN_OFFER: (event) => event?.tradeInOffer?.affectedUserLabel || '',
    };

    // Function to retrieve the source template
    const getReferentTemplate = (event: CustomerEvent) => {
        const eventType = `${event?.srcApplicationName}_${event?.processType}`;
        const templateFunction =
            referentTemplate[eventType] || (() => name(event?.eventUserFirstname, event?.eventUserLastname));

        return templateFunction(event);
    };
    return getReferentTemplate;
};

export default useReferentTemplate;

import React, { ReactElement } from 'react';
import { Image } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';

import { empty } from '@app/helpers/ToolsHelper';
import { getCurrentDealership, getUserDealershipById } from '@app/helpers/UserHelper';

import ExtendIcon from '@app/pages/customers/ExtendIcon';
import { leadTypes } from '@app/store/customers/customers.store';
import store from '@app/store/store';

const getCustomer = () => store.getState().customer.customer;

type ActionSlugType = {
    icon: ReactElement;
    title: ReactElement;
    variant: string;
    background?: string;
    permissions?: string[];
    typologies?: string[] | null;
    disabled?: () => boolean;
    disabledTooltip?: ReactElement;
};

export const DEALERSHIP_VISIT: ActionSlugType = {
    icon: <i className="action-button__icon la la-map-marker" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.DEALERSHIP_VISIT" />,
    variant: 'primary',
    permissions: ['LEAD_VISIT_DEALERSHIP_CONTACT'],
};

export const CALL: ActionSlugType = {
    icon: <i className="action-button__icon la la-phone" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.CALL" />,
    variant: 'primary',
    permissions: ['LEAD_CONSIGN_CALL_CONTACT'],
};

export const CALLBACK: ActionSlugType = {
    icon: <i className="action-button__icon la la-calendar-plus" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.CALLBACK" />,
    variant: 'primary',
    permissions: ['LEAD_CALLBACK_CONTACT'],
};

export const SCHEDULE_APPOINTMENT: ActionSlugType = {
    icon: <i className="action-button__icon la la-calendar" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.SCHEDULE_APPOINTMENT" />,
    variant: 'primary',
    permissions: ['LEAD_TAKE_APPOINTMENT_CONTACT'],
};

export const DECLARE_PURCHASE_ORDER: ActionSlugType = {
    icon: <i className="action-button__icon la la-edit" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.DECLARE_PURCHASE_ORDER" />,
    variant: 'primary',
    permissions: ['LEAD_CONSIGN_PURCHASE_ORDER_CONTACT'],
};

export const SEND_EMAIL: ActionSlugType = {
    icon: <i className="action-button__icon la la-envelope" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.SEND_EMAIL" />,
    variant: 'primary',
    permissions: ['LEAD_SEND_MAIL_CONTACT'],
    disabled: () => {
        const { data: customer } = getCustomer();
        return empty(customer?.email);
    },
    disabledTooltip: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.SEND_EMAIL.DISABLED" />,
};

export const VISIT_SIMPLE: ActionSlugType = {
    icon: <i className="action-button__icon la la-eye" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.VISIT_SIMPLE" />,
    variant: 'primary',
};

export const LOCAL_APV: ActionSlugType = {
    icon: <i className="action-button__icon la la-hand-pointer" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.LOCAL_APV" />,
    variant: 'primary',
    permissions: ['APV_LOCAL_LEAD_CREATE'],
};

export const LOCAL_COMMERCE: ActionSlugType = {
    icon: <i className="action-button__icon la la-car" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.LOCAL_COMMERCE" />,
    variant: 'primary',
    permissions: ['LEAD_CREATE_LOCAL_COMMERCIAL'],
};

export const LOCAL_HOME_CHARGE: ActionSlugType = {
    icon: <i className="action-button__icon la la-plug" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.LOCAL_HOME_CHARGE" />,
    variant: 'turquoise',
    permissions: ['LEAD_HOME_CHARGE_CREATE'],
};

export const VISIT_TRY: ActionSlugType = {
    icon: <i className="action-button__icon la la-car" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.VISIT_TRY" />,
    variant: 'primary',
};

export const DONE_APPOINTMENT: ActionSlugType = {
    icon: <i className="la la-2x text-white la-check" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.DONE_APPOINTMENT" />,
    variant: 'white',
    background: 'success',
};

export const CONFIRM_APPOINTMENT: ActionSlugType = {
    icon: <i className="la la-2x text-white la-calendar-check" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.CONFIRM_APPOINTMENT" />,
    variant: 'primary',
};

export const UNCONFIRM_APPOINTMENT: ActionSlugType = {
    icon: <i className="la la-2x text-white la-times" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.UNCONFIRM_APPOINTMENT" />,
    variant: 'white',
    background: 'danger',
};

export const UPDATE_APPOINTMENT: ActionSlugType = {
    icon: <i className="action-button__icon la la-pencil" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.UPDATE_APPOINTMENT" />,
    variant: 'primary',
};

export const CANCEL_APPOINTMENT: ActionSlugType = {
    icon: <i className="action-button__icon la la-times" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.CANCEL_APPOINTMENT" />,
    variant: 'primary',
};

export const CONFIRM_CALL: ActionSlugType = {
    icon: <i className="la la-2x text-white la-check" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.CONFIRM_CALL" />,
    variant: 'white',
    background: 'success',
};

export const UNCONFIRM_CALL: ActionSlugType = {
    icon: <i className="la la-2x text-white la-times" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.UNCONFIRM_CALL" />,
    variant: 'white',
    background: 'danger',
};

export const UPDATE_CALL: ActionSlugType = {
    icon: <i className="action-button__icon la la-pencil" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.UPDATE_CALL" />,
    variant: 'primary',
};

export const CANCEL_CALL: ActionSlugType = {
    icon: <i className="action-button__icon la la-times" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.CANCEL_CALL" />,
    variant: 'primary',
};

export const LEAD_WON: ActionSlugType = {
    icon: <i className="la la-3x text-white la-thumbs-up" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.LEAD_WON" />,
    variant: 'white',
    background: 'success',
    permissions: ['LEAD_CLOSE_WIN_LEAD'],
};

export const LEAD_LOST: ActionSlugType = {
    icon: <i className="la la-3x text-white la-thumbs-down" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.LEAD_LOST" />,
    variant: 'white',
    background: 'danger',
    permissions: ['LEAD_CLOSE_LOSE_LEAD'],
};

export const IN_STOCK: ActionSlugType = {
    icon: <i className="la la-2x text-white la-car" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.IN_STOCK" />,
    variant: 'primary',
};

export const OUT_STOCK: ActionSlugType = {
    icon: <i className="la la-2x text-white la-binoculars" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.OUT_STOCK" />,
    variant: 'primary',
};

export const FLUIDITY: ActionSlugType = {
    icon: <i className="la la-2x text-white la-exchange-alt" />,
    title: <FormattedHTMLMessage id="FLUIDITY.NAME" />,
    variant: 'primary',
    permissions: ['LEAD_LIST_FLUIDITE'],
};

export const CONFIRM_CONTACT: ActionSlugType = {
    icon: <i className="la la-2x text-white la-check" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.CONFIRM_CONTACT" />,
    variant: 'white',
    background: 'success',
};

export const CONTACT: ActionSlugType = {
    icon: <i className="action-button__icon la la-pencil" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.CONTACT" />,
    variant: 'primary',
};

export const LEAD_MANUFACTURER: ActionSlugType = {
    icon: <i className="action-button__icon la la-bullseye" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.LEAD_MANUFACTURER" />,
    variant: 'primary',
    permissions: ['LEAD_CREATE_MANUFACTURER'],
    typologies: [leadTypes.manufacturer],
};

export const ASSIGN_TO: ActionSlugType = {
    icon: <i className="la la-2x text-white la-exchange-alt" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.ASSIGN_TO" />,
    variant: 'white',
    background: 'primary',
    permissions: ['LEAD_ASSIGN_TO'],
};

export const CONTRACT_EXTENSION: ActionSlugType = {
    icon: <ExtendIcon />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.CONTRACT_EXTENSION" />,
    variant: 'primary',
    permissions: ['LEAD_CONTRACT_EXTENSION'],
};

export const LEAD_POSTPONE: ActionSlugType = {
    icon: <i className="action-button__icon la la-grip-lines-vertical" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.LEAD_POSTPONE" />,
    variant: 'primary',
};

export const CONTRACT_SECURYCAR: ActionSlugType = {
    icon: <i className="action-button__icon la la-shield-alt" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.CONTRACT_SECURYCAR" />,
    variant: 'primary',
    permissions: ['SECURYCAR_MANAGE_CONTRACT'],
    typologies: [leadTypes.securycar],
};
export const APV_IN_STORE: ActionSlugType = {
    icon: <i className="action-button__icon la la-wrench" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.APV_IN_STORE" />,
    variant: 'primary',
    permissions: ['LEAD_APV_IN_STORE'],
    typologies: ['local_apv_instore'],
};

export const APV_IN_STORE_WON: ActionSlugType = {
    icon: <i className="la la-3x text-white la-thumbs-up" />,
    title: <FormattedHTMLMessage id="APV.IN_STORE.QUOTATIONS.ACCEPTED" />,
    variant: 'white',
    background: 'success',
};

export const APV_IN_STORE_LOSE: ActionSlugType = {
    icon: <i className="la la-3x text-white la-thumbs-down" />,
    title: <FormattedHTMLMessage id="APV.IN_STORE.QUOTATIONS.REJECTED" />,
    variant: 'white',
    background: 'danger',
};

export const CREATE_OPPORTUNITY: ActionSlugType = {
    icon: <Image className="action-button--background" src="/media/icons/BMW-logo.svg" />,
    title: <FormattedHTMLMessage id="CUSTOMERS.ACTIONS.CREATE_OPPORTUNITY" />,
    variant: 'white',
    background: 'success',
    permissions: ['LEAD_CREATE_OPPORTUNITY'],
};

/**
 * Map all actions names
 */
export type actionsNameType = { [key in ActionsSlugValues]: ActionSlugType };
export const actionsName: actionsNameType = {
    DONE_APPOINTMENT,
    CONFIRM_APPOINTMENT,
    UNCONFIRM_APPOINTMENT,
    UPDATE_APPOINTMENT,
    CANCEL_APPOINTMENT,
    LEAD_WON,
    LEAD_LOST,
    SEND_EMAIL,
    DECLARE_PURCHASE_ORDER,
    CALLBACK,
    CALL,
    VISIT_SIMPLE,
    VISIT_TRY,
    LOCAL_APV,
    LOCAL_COMMERCE,
    LOCAL_HOME_CHARGE,
    SCHEDULE_APPOINTMENT,
    UPDATE_CALL,
    CANCEL_CALL,
    CONFIRM_CALL,
    UNCONFIRM_CALL,
    DEALERSHIP_VISIT,
    IN_STOCK,
    OUT_STOCK,
    FLUIDITY,
    CONTACT,
    CONFIRM_CONTACT,
    LEAD_MANUFACTURER,
    ASSIGN_TO,
    CONTRACT_EXTENSION,
    LEAD_POSTPONE,
    CONTRACT_SECURYCAR,
    APV_IN_STORE,
    APV_IN_STORE_WON,
    APV_IN_STORE_LOSE,
    CREATE_OPPORTUNITY,
};

/**
 * Map back slug to action
 */
type ActionsSlugValues = (typeof actionsSlug)[keyof typeof actionsSlug];
export const actionsSlug: { [key: string]: string } = {
    consign_appointment_done: 'DONE_APPOINTMENT',
    consign_appointment_fail: 'UNCONFIRM_APPOINTMENT',
    confirm_appointment: 'CONFIRM_APPOINTMENT',
    update_appointment: 'UPDATE_APPOINTMENT',
    cancel_appointment: 'CANCEL_APPOINTMENT',
    close_lead_won: 'LEAD_WON',
    close_lead_lost: 'LEAD_LOST',
    email: 'SEND_EMAIL',
    order_offer: 'DECLARE_PURCHASE_ORDER',
    appointment_callback: 'CALLBACK',
    call: 'CALL',
    visit: 'VISIT_SIMPLE',
    vehicle_test: 'VISIT_TRY',
    local_apv: 'LOCAL_APV',
    local_commerce: 'LOCAL_COMMERCE',
    local_home_charge: 'LOCAL_HOME_CHARGE',
    appointment: 'SCHEDULE_APPOINTMENT',
    callback: 'CALLBACK',
    update_callback: 'UPDATE_CALL',
    cancel_callback: 'CANCEL_CALL',
    consign_call_done: 'CONFIRM_CALL',
    consign_call_fail: 'UNCONFIRM_CALL',
    dealership_visit: 'DEALERSHIP_VISIT',
    in_stock: 'IN_STOCK',
    out_stock: 'OUT_STOCK',
    fluidity: 'FLUIDITY',
    consign_contact: 'CONTACT',
    consign_contact_done: 'CONFIRM_CONTACT',
    lead_manufacturer: 'LEAD_MANUFACTURER',
    assign_to: 'ASSIGN_TO',
    contract_extension: 'CONTRACT_EXTENSION',
    close_lead_postponed: 'LEAD_POSTPONE',
    contract_securycar: 'CONTRACT_SECURYCAR',
    apv_in_store: 'APV_IN_STORE',
    apv_in_store_won: 'APV_IN_STORE_WON',
    apv_in_store_lose: 'APV_IN_STORE_LOSE',
    create_opportunity: 'CREATE_OPPORTUNITY',
};

export const getActionSlug = (action: string): string | undefined => {
    for (const k of Object.keys(actionsSlug)) {
        if (action == actionsSlug[k]) {
            return k;
        }
    }
    return undefined;
};

/**
 * Return list of button action in function of slugs
 *
 */
export const getActions = <K extends keyof typeof actionsSlug>(slugs: K[]) => {
    if (!slugs) {
        return null;
    }

    const actions = Object.values(slugs).reduce<{ [k in ActionsSlugValues]: ActionSlugType }>((acc, action) => {
        if (actionsSlug[action]) {
            const slug: ActionsSlugValues = actionsSlug[action];
            acc[slug] = actionsName[slug];
        }

        return acc;
    }, {});

    return Object.entries(actions);
};

interface Vehicle {
    sku: string;
    projectComment: string;
}

export type FormattedActionData = {
    contactId: string;
    lead: {
        id?: string;
        origin?: string;
        type: string;
        source: string;
        dealershipId: string;
        dealershipName: string;
    };
    user: {
        id: number;
        firstname: string;
        lastname: string;
        email: string;
        role?: string;
    };
    dealership?: object;
    vehicles?: Vehicle[];
};
/**
 * Return formatted action data
 */
// @ts-ignore TODO remove when customer is typed
export const formatActionData = (data) => {
    const { userData } = store.getState().auth;
    const { data: customer } = store.getState().customer.customer;
    const { params } = store.getState().modal;
    const dealership = getUserDealershipById(data?.dealership) || getCurrentDealership();
    let leadType = 'local';
    let dealer: FormattedActionData['user'];
    let dataToReturn = data;

    if (typeof dataToReturn.dealership !== 'object') {
        delete dataToReturn.dealership;
    }

    if (dataToReturn.lead_type) {
        leadType = dataToReturn.lead_type;
        delete dataToReturn.lead_type;
    }

    if (dataToReturn.dealer) {
        dealer = dataToReturn.dealer;
        delete dataToReturn.dealer;
    } else {
        dealer = {
            id: userData.id,
            firstname: userData.first_name,
            lastname: userData.last_name,
            email: userData.email,
        };
    }

    dataToReturn = {
        contactId: customer.id,
        lead: {
            type: leadType,
            dealershipId: dealership?.id,
            dealershipName: dealership?.name,
        },
        user: dealer,
        ...dataToReturn,
    };

    if (dataToReturn.lead_origin) {
        dataToReturn = {
            ...dataToReturn,
            lead: {
                ...dataToReturn.lead,
                origin: dataToReturn.lead_origin,
            },
        };
        delete dataToReturn.lead_origin;
    }

    if (params?.leadId) {
        dataToReturn = {
            ...dataToReturn,
            lead: {
                id: params.leadId,
            },
        };
    }

    if (['email', 'join', 'dissociate', 'treat_separately'].includes(dataToReturn.actionType)) {
        delete dataToReturn.lead;
    }

    if (['create_opportunity', 'close_confirmed_follow_up_task_status'].includes(dataToReturn.actionType)) {
        delete dataToReturn.user;
    }

    if (dataToReturn?.vehicles) {
        dataToReturn.vehicles = dataToReturn.vehicles.map((vehicle: Vehicle) =>
            vehicle?.sku ? { vehicleInternalId: vehicle.sku, projectComment: vehicle?.projectComment } : vehicle,
        );
    }

    // Remove empty fields
    dataToReturn = Object.entries(dataToReturn).reduce(
        (acc, [param, value]) => (!empty(value) ? { ...acc, [param]: value } : acc),
        {},
    );

    return dataToReturn;
};

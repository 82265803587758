import dayjs from 'dayjs';
import React from 'react';
import { Button } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import NumberFormat from 'react-number-format';

import { getCustomerEventSource } from '@app/pages/customers/Helpers/Events';
import LeadStatusRow from '@app/pages/customers/Partials/LeadStatusRow';

const HistoryRow = ({ data, className, arrowDirection, isStart, isEnd, onShowDetails }) => {
    const source = getCustomerEventSource(data);
    const Intl = useIntl();

    let price = '';
    if (data?.sellingPriceWithTaxes && data?.sellingPriceWithTaxes > 0) {
        price = data?.sellingPriceWithTaxes;
    } else if (data?.leadVehPrice && data?.leadVehPrice > 0) {
        price = data?.leadVehPrice;
    } else if (data?.interventionPriceWithTaxes && data?.interventionPriceWithTaxes > 0) {
        price = data?.interventionPriceWithTaxes;
    } else if (data?.commercialProposal?.price && data?.commercialProposal?.price > 0) {
        price = data?.commercialProposal?.price;
    } else if (data?.tradeInOffer?.price && data?.tradeInOffer?.price > 0) {
        price = data?.tradeInOffer?.price;
    }

    const translatorTimeline = () => {
        if (isStart) {
            return (
                <div className="lead-history__start">
                    <i className="lar text-primary la-circle lead-history__icon" />
                    <div className="text-primary lead-history__time font-weight-bold">
                        {Intl.formatMessage({ id: 'TRANSLATOR.NOW' })}
                    </div>
                </div>
            );
        } else if (isEnd) {
            return (
                <div className="lead-history__end">
                    <i className="lar text-primary la-circle lead-history__icon" />
                    <div className="text-primary lead-history__time font-weight-bold">
                        {Intl.formatMessage({ id: 'TRANSLATOR.BEGINNING' })}
                    </div>
                </div>
            );
        }

        return <i className={`lead-history__icon lead-history__arrow las text-primary la-angle-${arrowDirection}`} />;
    };

    const isEventWithHistoryDetails = [
        'fusion',
        'update_consent_contact',
        'update_consent_dealership',
        'create_contact',
        'update_contact',
    ].includes(data?.eventTypeSlug);

    return (
        <div className={`lead-history__col ${className}`}>
            <div className="lead-history__wrapper">
                <div className="lead-history__content">
                    {data?.leadTypeName && data?.leadId && (
                        <div className="flex-grow-1 font-weight-bold">
                            {data?.leadTypeName} - {data?.leadId}
                        </div>
                    )}
                    {data?.leadReceipted && (
                        <div>
                            {Intl.formatMessage({ id: 'CUSTOMER.VIEW.HISTORY.CREATED' })}&nbsp;
                            {dayjs(data.leadReceipted).isValid()
                                ? dayjs(data.leadReceipted).format(
                                      data?.leadId ? 'DD/MM/YYYY [à] HH[h]mm' : 'DD/MM/YYYY',
                                  )
                                : ''}
                        </div>
                    )}
                    {data?.eventName && <div className="flex-grow-1 font-weight-bold">{data.eventName}</div>}
                    {price && (
                        <NumberFormat
                            value={price || '-'}
                            decimalSeparator="."
                            thousandSeparator=" "
                            className="text-nowrap"
                            allowedDecimalSeparators={[',', '.']}
                            suffix=" €"
                            displayType="text"
                        />
                    )}
                    {source && (
                        <div className={`flex-grow-1 lead-history__source--${source?.slug}`}>
                            &bull;&nbsp;{source?.name}
                            {source.slug === 'action_email' && (
                                <span>
                                    {' '}
                                    {Intl.formatMessage({ id: 'SEND.BY' })} <br /> {data?.eventRawFirstname}{' '}
                                    {data?.eventRawLastname}
                                </span>
                            )}
                        </div>
                    )}
                    {data?.leadStatusSlugHisto && (
                        <LeadStatusRow
                            leadStatusSlug={data?.leadStatusSlugHisto}
                            leadStatusName={data?.leadStatusNameHisto}
                            leadStatusSubName={data?.leadStatusSubNameHisto}
                            shift=""
                            className="flex-grow-1"
                        />
                    )}

                    {data?.eventTypeSlug === 'update_consent_contact' && (
                        <div>{`${data?.snapshotContactFirstname} ${data?.snapshotContactLastname}`}</div>
                    )}

                    {data?.eventTypeSlug === 'update_consent_dealership' && (
                        <div>{`${data?.eventRawFirstname} ${data?.eventRawLastname}`}</div>
                    )}

                    {data?.eventCreated && (
                        <div>
                            {Intl.formatMessage({ id: 'CUSTOMER.VIEW.HISTORY.UPDATE' })}&nbsp;
                            {dayjs(data.eventCreated).isValid()
                                ? dayjs(data.eventCreated).format(
                                      data?.leadId || isEventWithHistoryDetails
                                          ? 'DD/MM/YYYY [à] HH[h]mm'
                                          : 'DD/MM/YYYY',
                                  )
                                : ''}
                        </div>
                    )}

                    {isEventWithHistoryDetails && data?.eventRawFirstname && data?.eventRawLastname && (
                        <div>
                            {Intl.formatMessage({ id: 'CUSTOMER.VIEW.HISTORY.BY' })}&nbsp;
                            {`${data?.eventRawFirstname} ${data?.eventRawLastname}`}
                        </div>
                    )}

                    {data?.eventTypeSlug !== 'fusion' ? (
                        <Button
                            className="mt-2"
                            variant="primary"
                            onClick={() => {
                                onShowDetails(data);
                            }}
                        >
                            {Intl.formatMessage({ id: 'TRANSLATOR.SHOW_DETAILS' })}
                        </Button>
                    ) : (
                        <div className="p-7" />
                    )}
                    {translatorTimeline}
                </div>
            </div>
        </div>
    );
};

HistoryRow.defaultProps = {
    className: '',
    arrowDirection: 'right',
    isStart: false,
    isEnd: false,
};

export default HistoryRow;

import { CustomerEvent } from '@app/crud/customers/customer.type';

interface priceFunctions {
    [key: string]: (event: CustomerEvent) => string | number;
}

const usePriceTemplate = () => {
    const emptyTemplate = () => '-';
    let priceTemplateValue = '';

    const priceTemplate: priceFunctions = {
        DATASTORE_FUNDING: (event: CustomerEvent) => {
            if (event?.fundingPrice?.indexOf(',') !== -1) {
                // Reformat price with comma to point
                priceTemplateValue = event.fundingPrice.replace(',', '.');
            }
            return priceTemplateValue;
        },
        ICARE_MAINTENANCE_CONTRACT: () => emptyTemplate(),
        MECAPLANNING_AFTER_SALES_APPOINTMENT: () => emptyTemplate(),
        CIRANO_WARRANTY_CONTRACT: () => emptyTemplate(),
        SECURYCAR_SC_SERVICE_RESUME: (event) => event?.scServiceResumeSellingPriceWithTaxes,
        AUTOSPHERE_FR_TECHNICAL_INTERVENTION_APPOINTMENT_CANCELED: (event) => event?.appointmentOrderPaymentAmount,
        AUTOSPHERE_FR_TECHNICAL_INTERVENTION_APPOINTMENT: (event) => event?.appointmentOrderPaymentAmount,
        AUTODELIVERY_ORDERS_WEB: (event) => event?.orderwebTotal,
        AUTODELIVERY_ORDERS_WEB_RESUME: (event) => event?.orderwebTotal,
        AUTODELIVERY_ORDERS_WEB_FOLLOWUP: (event) => event?.orderwebTotal,
        PLANET_VO2_COMMERCIAL_PROPOSAL: (event) => event?.commercialProposal?.price,
        PLANET_VO2_TRADE_IN_OFFER: (event) => event?.tradeInOffer?.price,
    };

    // Function to retrieve the source template
    const getPriceTemplate = (event: CustomerEvent) => {
        const eventType = `${event?.srcApplicationName}_${event?.processType}`;
        const templateFunction =
            priceTemplate[eventType] ||
            (() => event?.leadVehPrice || event?.sellingPriceWithTaxes || event?.interventionPriceWithTaxes);

        return templateFunction(event);
    };
    return getPriceTemplate;
};

export default usePriceTemplate;

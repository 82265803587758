import classNames from 'classnames';
import { Chip } from 'primereact/chip';
import React, { useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { FastNote } from '@app/crud/administration/fast-notes.type';

import { Lead } from '@app/store/customers/customers.store';
import { actions } from '@app/store/modal/modal.store';

import { hideDetailEvent } from '../Helpers/Events';
import { getLeadId } from '../Helpers/Lead';
import useActionClient from '../Hooks/useActionClient';
import UpdateCommentClient from '@app/crud/action/update-comment';

const DISPLAYED_LEADS = 2;

type TagsListProps = {
    tags: FastNote[];
    selectedTags: FastNote[];
    lead: Lead;
    className?: string;
    limited?: boolean;
    showAll?: boolean;
    linkTagToLead: (() => Promise<void>) | any;
    unlinkTagToLead: (() => Promise<void>) | any;
};

const TagsList = ({
    tags,
    className,
    limited,
    showAll,
    selectedTags,
    linkTagToLead,
    unlinkTagToLead,
    lead,
}: TagsListProps) => {
    const dispatch = useDispatch();
    const Intl = useIntl();

    const [caller] = useActionClient(new UpdateCommentClient(), {
        notifySucess: Intl.formatMessage({ id: 'TRANSLATOR.SUCCESS' }),
        notifyFail: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
    });

    const handleUpdateLead = (data: any) => {
        caller({
            lead: getLeadId(lead),
            comment: data.comment,
        }).then(() => {
            const showDetailButton = document.querySelector(`#event-${getLeadId(lead)}`);
            hideDetailEvent(showDetailButton);

            dispatch(actions.closeModals());
        });
    };

    const isLinkToLead = useCallback((tag: FastNote) => selectedTags?.includes?.(tag), [selectedTags]);

    const handleLimitedClick = () => {
        dispatch(
            actions.modalChange('comment', {
                callback: handleUpdateLead,
                withTags: true,
                comment: lead?.comment,
                lead,
            }),
        );
    };

    const handleChipClick = (tag: FastNote, active: boolean) => {
        if (showAll) return;

        if (limited) {
            handleLimitedClick();
            return;
        }

        if (active) {
            unlinkTagToLead(tag);
        } else {
            linkTagToLead(tag);
        }
    };

    const limitedFilteredTags = useMemo(
        () => tags?.filter?.((tag) => (limited ? isLinkToLead(tag) : true)) || [],
        [isLinkToLead, limited, tags],
    );

    const getSliceLength = () => {
        if (limited && !showAll) {
            return DISPLAYED_LEADS;
        }

        return limitedFilteredTags?.length;
    };

    const showLimitedChip = !showAll && limited && limitedFilteredTags.length > DISPLAYED_LEADS;

    return (
        <div>
            {limitedFilteredTags?.slice?.(0, getSliceLength())?.map?.((tag) => {
                const active = isLinkToLead(tag);

                return (
                    <Chip
                        key={tag?.id}
                        template={
                            <div
                                role="presentation"
                                onClick={() => handleChipClick(tag, active)}
                                className={classNames({
                                    'cursor-pointer': !showAll,
                                })}
                            >
                                {tag?.name}
                                {!limited && <i className="ml-2 las la-1x la-times" />}
                            </div>
                        }
                        className={classNames('font-weight-semibold my-1 mr-2', className, {
                            'p-chip-warning': active,
                            'p-chip-outline-secondary': !active,
                        })}
                    />
                );
            })}
            {showLimitedChip && (
                <Chip
                    className="font-weight-semibold p-chip-warning p-chip-wrapper cursor-pointer"
                    template={
                        <div role="presentation" className="p-chip-content" onClick={handleLimitedClick}>
                            {`+ ${limitedFilteredTags.length - DISPLAYED_LEADS}`}
                        </div>
                    }
                />
            )}
        </div>
    );
};

TagsList.defaultProps = {
    className: '',
    limited: false,
    showAll: false,
};

export default TagsList;

import ApiGateway, { ApiResponse, CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { ActionClient, ActionPayload, ActionRelatedTo, ActionResponse } from './base';

const { REACT_APP_LEAD_URL } = process.env;

export default class ConsignClient implements ActionClient<ConsignPayload, ConsignResponse> {
    public async execute(payload: ConsignPayload): Promise<ConsignResponse> {
        const { lead, ...others } = payload;
        return ApiGateway.post<ApiResponse<ConsignResponse>, CustomAxiosResponse<ApiResponse<ConsignResponse>>>(
            `${REACT_APP_LEAD_URL}/action/consign-action`,
            others,
        ).then((response: ApiResponse<ConsignResponse>) => {
            if (response.result?.error) {
                throw new Error(response.result?.error);
            }

            return {
                relatedToLead: response.result.action.lead.id,
                relatedToCustomer: response.result.action.lead.contact.id,
                ...response.result,
            };
        });
    }

    public readonly action: string = 'consign';

    public readonly relatedTo: ActionRelatedTo = 'lead';

    public reference(payload: ConsignPayload): number {
        return payload.lead;
    }
}

export interface ConsignPayload extends ActionPayload {
    lead: number;
    scheduleAppointment: number;
    result: string;
    success: boolean;
}

export interface ConsignResponse extends ActionResponse {
    id: number;
    action: {
        lead: {
            id: number;
            contact: {
                id: number;
            };
        };
    };
}

import { CommercialProposal } from '@app/crud/customers/customer.type';
import StatusLead from '@app/pages/customers/Partials/StatusLead';
import React from 'react';
import { useIntl } from 'react-intl';

type Props = {
    commercialProposal?: CommercialProposal;
};

const CommercialProposalStatus = ({ commercialProposal }: Props) => {
    const Intl = useIntl();

    if (!commercialProposal?.status) {
        return <span>-</span>;
    }

    let statusType;
    switch (commercialProposal?.status) {
        case '0':
            statusType = 'primary';
            break;
        case '1':
            statusType = 'success';
            break;
        default:
            statusType = 'secondary';
            break;
    }

    const status = `CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.${commercialProposal?.order ? 'ORDER' : 'OFFER'}.STATUS.${
        commercialProposal?.status
    }`;
    return <StatusLead overridedStatus={statusType} statusName={Intl.formatMessage({ id: status })} />;
};

export default CommercialProposalStatus;

import { useCallback, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { CustomAxiosResponse } from '@app/helpers/AxiosHelper';
import { empty } from '@app/helpers/ToolsHelper';

import useCounter from './useCounters';
import useFetch from './useFetch';
import useFilters from './useFilters';

export interface Sorts {
    sortField: string;
    sortOrder: number;
}
export interface Pagination {
    page: number;
    first: number;
    rows: number;
}
export interface TableHandler<T> {
    handleFilter: (value: unknown) => void;
    handlePagination: (e: Pagination) => void;
    handleSort: (e: Sorts) => void;
    loading: boolean;
    data: T;
}

type TableHandlersProps<T, S> = {
    filterStore?: string;
    fetchAction: (params: T) => Promise<CustomAxiosResponse<S>>;
    doSearch?: (params: any, extraParams: any | null) => boolean;
    withStore?: boolean;
    extraParams?: unknown;
    needUrlParams?: boolean;
    counterName?: string | null;
    paramsInterceptor?: (params: any, filters: any, extraParams: any | null) => any;
};

const useTableHandlers = <T, S>({
    filterStore,
    fetchAction,
    doSearch = () => true,
    withStore = true,
    extraParams = null,
    counterName = null,
    needUrlParams = false,
    paramsInterceptor = (params: T, filters, extraParams) => ({
        ...params,
        start: filters?.start,
        items: filters?.items,
    }),
}: TableHandlersProps<T, S>) => {
    const counter = useCounter(counterName, false);
    const { fetch, data, loading, error, setData } = useFetch<T, S>({
        fetchAction,
        resultInterceptor: (response) => response || [],
    });
    const filterValues = useFilters({
        name: filterStore,
        initialize: false,
    });
    const { items, start, updatedFilters, sortOrder, sortField, updateFilters } = filterValues;
    const timeout = useRef(0);
    const timer = useRef(undefined);
    const dispatch = useDispatch();

    const refresh = useCallback(() => {
        clearTimeout(timer.current);

        timer.current = setTimeout(() => {
            let params = {
                ...Object.entries(updatedFilters).reduce((acc, [name, filter]) => {
                    if (Array.isArray(filter)) {
                        return { ...acc, [name]: filter };
                        // @ts-ignore
                    } else if (!empty(filter?.value)) {
                        // @ts-ignore
                        return { ...acc, [name]: filter.value };
                    }
                    return acc;
                }, {} as T),
            };

            // Check if params is empty
            if (needUrlParams) {
                if (Object.keys(params).length === 0) {
                    return false; // Skip the fetch request
                }
            }

            // Apply interceptor
            params = paramsInterceptor(params, filterValues, extraParams);

            if (!doSearch(params, extraParams)) {
                return false;
            }

            if (withStore) {
                dispatch(fetchAction(params));
            } else {
                fetch(params);
            }
        }, timeout.current);
    }, [dispatch, fetchAction, updatedFilters, items, start, sortOrder, sortField, counter, extraParams]);

    /**
     * Fetch data in first load
     */
    useEffect(() => {
        refresh();
    }, [refresh]);

    /**
     * Datatable filter handler
     *
     * @param e
     * @param withTimeout
     */
    const handleFilter = useCallback(
        (value: unknown, withTimeout: boolean = undefined) => {
            timeout.current = withTimeout ? 500 : 0;

            updateFilters({
                filters: value,
                page: 1,
            });
        },
        [updateFilters],
    );

    /**
     * Datatable pagination handler
     *
     * @param e
     */
    const handlePagination = useCallback(
        (e: Pagination) => {
            timeout.current = 0;
            updateFilters({
                items: e.rows,
                page: e.page + 1,
                start: e.first,
            });
        },
        [updateFilters],
    );

    /**
     * Datatable sort handler
     *
     * @param e
     */
    const handleSort = useCallback(
        (e: Sorts) => {
            timeout.current = 0;

            updateFilters({
                sortField: e.sortField,
                sortOrder: e.sortOrder,
            });
        },
        [updateFilters],
    );

    return {
        handleFilter,
        handlePagination,
        handleSort,
        refresh,
        data,
        loading,
        error,
        setData,
    } as const;
};

export default useTableHandlers;

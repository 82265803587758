/**
 * Entry application component used to compose providers and render Routes.
 * */

import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { Store } from 'redux';
import { Persistor } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';

import CacheBusting from '@app/partials/content/CacheBusting';

import './app/helpers/StringHelper';
import { I18nProvider, LayoutSplashScreen } from './_metronic';
import CheckConnection from './app/partials/content/CheckConnection';
import GlobalHistory from './app/partials/content/GlobalHistory';
import RoutesWrapper from './app/router/RoutesWrapper';

interface AppProps {
    store: Store;
    persistor: Persistor;
    basename: string;
}

const { REACT_APP_GTAG_ID } = process.env;
const tagManagerArgs = {
    gtmId: REACT_APP_GTAG_ID,
};

export default function App({ store, persistor, basename }: AppProps) {
    useEffect(() => {
        TagManager.initialize(tagManagerArgs);
    }, []);

    return (
        /* Provide Redux store */
        // @ts-ignore
        <Provider store={store}>
            {/* Asynchronously persist redux stores and show `SplashScreen` while it's loading. */}
            <PersistGate persistor={persistor} loading={<LayoutSplashScreen />}>
                {/* Add high level `Suspense` in case if was not handled inside the React tree. */}
                <React.Suspense fallback={<LayoutSplashScreen />}>
                    {/* Override `basename` (e.g: `homepage` in `package.json`) */}
                    <BrowserRouter basename={basename}>
                        {/* This library only returns the location that has been active before the recent location change in the current window lifetime. */}
                        <LastLocationProvider>
                            {/* Provide `react-intl` context synchronized with Redux state.  */}
                            <I18nProvider>
                                {/* Handle cache bouding */}
                                <CacheBusting>
                                    {/* Render routes with provided `Layout`. */}
                                    <RoutesWrapper />
                                    {/* Check User Connection */}
                                    <CheckConnection />
                                    {/* Handle globals history */}
                                    <GlobalHistory />
                                </CacheBusting>
                            </I18nProvider>
                        </LastLocationProvider>
                    </BrowserRouter>
                </React.Suspense>
            </PersistGate>
        </Provider>
    );
}

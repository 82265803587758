import React, { ReactNode } from 'react';

import { CustomAxiosResponse } from '@app/helpers/AxiosHelper';

import { LeadDetails } from '@app/crud/customers/customer.type';

type FetchAction = (id: string) => Promise<CustomAxiosResponse<LeadDetails>>;

interface EventDetailContextInterface {
    fetchData: FetchAction;
}
export const EventDetailContext = React.createContext({} as EventDetailContextInterface);

type EventDetailProviderProps = {
    value: EventDetailContextInterface;
    children: ReactNode;
};

const EventDetailProvider = ({ value, children }: EventDetailProviderProps) => (
    <EventDetailContext.Provider value={value}>{children}</EventDetailContext.Provider>
);

export default EventDetailProvider;

import { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { isNewRootLocation } from '../helpers/ToolsHelper';
import { actions, datatableInitValues } from '../store/filters/filters.store';

import { useAppDispatch } from './useAppDispatch';
import { useAppSelector } from './useAppSelector';

export interface Filters extends PossibleFilters {
    updateFilters: Function;
    resetAllFilters: Function;
    resetFilters: Function;
    resetFilter: Function;
    updatePendingFilters: Function;
    releasePendingFilters: Function;
}
interface PossibleFilters {
    filters?: Object;
    pendingFilters?: Object;
    sortField?: string;
    sortOrder?: number;
    items?: number;
    page?: number;
    start?: number;
}
export type Filter = {
    items: number;
    start: number;
    page: number;
    filters: any;
    updatedFilters: any;
    sortField: string;
    sortOrder: any;
    updateFilters: (filters: PossibleFilters) => void;
    resetAllFilters: (defaultValues?: PossibleFilters) => void;
    resetFilters: (filters: string[]) => void;
    resetFilter: (filter: string) => void;
    updatePendingFilters: (filters: PossibleFilters) => void;
    releasePendingFilters: () => void;
};

const useFilters = ({
    name,
    persisted = true,
    defaultValues = {},
    initialize = true,
    path = null,
}: {
    name: string;
    persisted?: boolean;
    defaultValues?: PossibleFilters;
    initialize?: boolean;
    path?: string | null;
}): Filter => {
    const filters = useAppSelector((state) => state.filters[name]);
    const prevRoute = useAppSelector((state) => state.route.prevRoute);
    const location = useLocation();
    const dispatch = useAppDispatch();

    // Create filter in store if not exist
    useEffect(() => {
        if (!filters && initialize) {
            dispatch(actions.resetAllFilters(name, defaultValues));
        }
    }, [dispatch, filters, name, initialize, persisted]);

    // Reset filters if not persisted
    useEffect(() => {
        if (!persisted && isNewRootLocation(location?.pathname, prevRoute?.match?.path)) {
            if (!path || path !== location?.pathname) {
                dispatch(actions.resetAllFilters(name, defaultValues));
            }
        }
    }, [dispatch, persisted, name, prevRoute, path]);

    /**
     * Dispatch new filters on store
     *
     * @param filters
     */
    const updateFilters = useCallback(
        (filters: PossibleFilters) => {
            dispatch(actions.setFilters(name, filters));
        },
        [dispatch, name],
    );

    /**
     * Dispatch new filters on store
     *
     * @param filters
     */
    const resetAllFilters = useCallback(
        (defaultValues?: PossibleFilters) => {
            dispatch(actions.resetAllFilters(name, defaultValues));
        },
        [dispatch, name],
    );

    /**
     * Dispatch new filters on store
     *
     * @param filter
     */
    const resetFilters = useCallback(
        (filters: string[]) => {
            dispatch(actions.resetFilters(name, filters));
        },
        [dispatch, name],
    );

    /**
     * Dispatch new filters on store
     *
     * @param filter
     */
    const resetFilter = useCallback(
        (filter: string) => {
            dispatch(actions.resetFilters(name, [filter]));
        },
        [dispatch, name],
    );

    /**
     * Update pending filters
     *
     * @param filters
     */
    const updatePendingFilters = useCallback(
        (filters: PossibleFilters) => {
            updateFilters({
                pendingFilters: filters,
            });
        },
        [filters],
    );

    /**
     * Release pending filters
     */
    const releasePendingFilters = useCallback(() => {
        resetAllFilters({
            filters: {
                ...(filters?.filters || datatableInitValues.filters),
                ...(filters?.pendingFilters || datatableInitValues.pendingFilters),
            },
        });
    }, [filters]);

    const items = filters?.items || datatableInitValues.items;
    const page = filters?.page || datatableInitValues?.page;
    const start = (page - 1) * items;

    return {
        items,
        start,
        page,
        filters: {
            ...(filters?.filters || datatableInitValues.filters),
            ...(filters?.pendingFilters || datatableInitValues.pendingFilters),
        },
        updatedFilters: filters?.filters || datatableInitValues.filters,
        sortField: filters?.sortField || datatableInitValues.sortField,
        sortOrder: filters?.sortOrder || datatableInitValues.sortOrder,
        updateFilters,
        resetAllFilters,
        resetFilters,
        resetFilter,
        updatePendingFilters,
        releasePendingFilters,
    } as const;
};

export default useFilters;

import { Dayjs } from 'dayjs';

import { DealershipKnow } from '@app/crud/securyweb/securyweb.types';

import { Consent, Lead } from '@app/store/customers/customers.store';
import { Vehicle } from '@app/store/stock/stock.type';

export interface ExternalData {
    active: boolean;
    reference: string;
    source: {
        name: string;
        slug: string;
    };
}

export interface Customer {
    id?: number;
    civility?: 'mr' | 'm' | 'mme' | 'Mr' | 'Mme';
    firstname?: string;
    lastname?: string;
    email?: string;
    phone?: string;
    mobile?: string;
    address?: string;
    additionalAddress?: string;
    country?: string;
    zipcode?: string;
    city?: string;
    birthday?: string;
    vehicleKnow?: VehicleKnow[] | string;
    dealershipKnow?: string | DealershipKnow[];
    isClient?: boolean;
    isAnonymized?: boolean;
    vcuId?: string;
    autosphereId?: null;
    deliveryId?: null;
    dateAnonymized?: null;
    created?: string;
    updated?: string;
    consents?: Consent[];
    rpaIdentifiants?: string;
    isPro?: boolean;
    siret?: string;
    siren?: string;
    companyName?: string;
    companyPhone?: string;
    proPhone?: string;
    companyLegalStatus?: string;
    companyActivity?: string;
    ape?: string;
    role?: null;
    leads?: Lead[];
    differentialsVCU?: unknown;
    last_action?: unknown;
    gdrp?: [];
    md5?: null;
    function?: string;
    gdprDealerships?: ConsentReturn[];
}

export interface ConsentReturn {
    refDealershipId: string;
    refDealershipName: string;
    gdprSms: boolean;
    gdprPhone: boolean;
    gdprEmail: boolean;
    gdprAddress: boolean;
}

export interface VehicleKnow {
    bodyType: string;
    brand: string;
    circulationDate: string;
    color: string;
    energy: string;
    lastActivityBetweenThem: string;
    lastMileageKnown: string;
    lastProductType: string;
    model: string;
    registrationNumber: string;
    registrationNumberDate: string;
    sellingPrice: string;
    vehicleUuid: string;
    version: string;
    vin: string;
}

export interface PartExchange {
    comment: string;
    dateFirstRegistration: string;
    estimatedPrice: number;
    estimatedPriceRebate: number;
    estimationDate: string;
    fuelType: string;
    gearbox: string;
    id: number;
    image: string;
    make: string;
    mileage: number;
    model: string;
    partExchangeReference: string;
    registration: string;
    registrationCertificate: string;
    registrationCertificateAbsenceReason: string;
    status: string;
    version: string;
    vin: string;
    vehicleType: string;
    coHolder: string;
    typeVarVersPrf: string;
    isVehicleModified?: boolean;
}

interface Informations {
    identifier: number;
    leadTypeSlug: string;
}

interface Project {
    customerProject: string;
}

export interface EventDetailType {
    partExchange: PartExchange[];
    informations: Informations;
    project: Project;
}

interface FastNote {
    id: number;
    name: string;
    slug: string;
    created: string;
    updated: string;
}

interface ApiFacet {
    key: string;
    doc_count: number;
    label: string;
}
export interface LeadsFacets {
    dealershipIds: ApiFacet[];
    leadOrigins: ApiFacet[];
    leadSources: ApiFacet[];
    leadStatus: ApiFacet[];
    leadTypes: ApiFacet[];
    leadTypesGroups: ApiFacet[];
}
export interface LeadDetailsInformations {
    identifier: number;
    leadType: string;
    leadTypeSlug: string;
    leadTypeGroup: string;
    leadTypeGroupSlug: string;
    source: string;
    sourceSlug: string;
    contactType: null;
    contactSlug: null;
    created: string;
    receipted: string;
    callTransfer: string | null;
    rdv: null;
    homeTrial: boolean;
    communicationChannel: null;
    userFullname: string;
    status: string;
    statusSlug: string;
    subStatus: null;
    dealership: {
        make: string;
        city: string;
        zipcode: string;
        name: string;
        identifier: string;
        codePvo: string;
    };
    leadOrigin: string;
    leadOriginSlug: string;
    flags: string[];
    result: {
        result: null;
        vehicle: null;
        vehicleIdentifier: null;
        vehicleType: null;
        billing: null;
        tool: null;
        reference: null;
        clientName: null;
    } | null;
    fastNotes: FastNote[];
    comment: string;
    campaignId: null;
    operations: {
        name: string;
        slug: string;
    }[];
    apvRequest: {
        service: string;
        vehicle_registration: string;
        make: string;
        model: string;
        action_type: string;
        action_source: string;
    };
    leadClosLibel: string;
    processType: string;
    createdBy: {
        firstname: string;
        lastname: string;
    };
}

export interface VcuMappedEventDetails {
    price?: string | null;
    action?: {
        date: string | null;
    };
    product?: {
        date_delivery: string | null;
        funding_type: string | null;
        bring: string | null;
        amount_financed: string | null;
        duration: string | null;
        political_code: string | null;
        pack: string | null;
        redemption_value: string | null;
        km_annual_planned: string | null;
        vivafi_id: string | null;
        telefi_id: string | null;
    }[];
    informations: {
        identifier: string | null;
        leadType: string | null;
        leadTypeSlug: string | null;
        status: string | null;
        statusSlug: string | null;
        subStatus: string | null;
        leadClosLibel: string | null;
        contactType: string | null;
        price: string | null;
        description: string | null;
        dealership: {
            name: string | null;
        };
        source: string | null;
        billType: string | null;
        created: string | null;
        processType: string | null;
        document: string | null;
        emailAccountAfr: string | null;
        refDealershipId: string | null;
    };
    project: {
        customerProject: null;
        vehicles: {
            projectComment: null;
            registration: string | null;
            circulationDate: string | null;
            price: string | null;
            version: string | null;
            make: string | null;
            model: string | null;
            mileage: string | null;
            fuelType: string | null;
            year: string | null;
            color: string | null;
            vin: string | null;
            registrationNumberDate: string | null;
            lastMileageKnow: string | null;
            type: string | null;
        }[];
    };
    appointment: {
        details: {
            appointmentAppointmentDateStart: string | null;
            comment: string | null;
            appointmentServices: string | null;
            appointmentTotalOrderAmount: string | null;
        };
    };
    securycar: {
        resume: {
            contractId: string | null;
            contrat: string | null;
            startDate: string | null;
            endDate: string | null;
            paymentMode: string | null;
            subscriptionDate: string | null;
            price: string | null;
            leadFollowby: string | null;
            source: string | null;
            contratNumber: string | null;
        }[];
        disaster: {
            contractId: string | null;
            type: string | null;
            isSupported: string | null;
            supportedAmountWithTaxes: string | null;
            disasterDate: string | null;
            declarationDate: string | null;
            closureDate: string | null;
            insuranceCompany: string | null;
            guaranteeEndDate: string | null;
            source: string | null;
            contratNumber: string | null;
        }[];
        renewal: {
            contractId: string | null;
            startDate: string | null;
            endDate: string | null;
            paymentMode: string | null;
            subscriptionDate: string | null;
            contractNumber: string | null;
            source: string | null;
            product: string | null;
        }[];
        termination: {
            contractId: string | null;
            contractNumber: string | null;
            terminationDate: string | null;
            reasonSimple: string | null;
        }[];
    };
    fullWeb: {
        orderwebAccessoires: string | null;
        orderwebAcompte: string | null;
        orderwebAcompteDate: string | null;
        orderwebAcompteMode: string | null;
        orderwebAcompteValidBy: string | null;
        orderwebAnnonceurbasecodepvo: string | null;
        orderwebApport: string | null;
        orderwebApportDate: string | null;
        orderwebApportValidBy: string | null;
        orderwebAutosphereId: string | null;
        orderwebCartegriseTarif: string | null;
        orderwebClientId: string | null;
        orderwebDateCancel: string | null;
        orderwebDateCreate: string | null;
        orderwebDateMaj: string | null;
        orderwebDealerId: string | null;
        orderwebDiscount: string | null;
        orderwebFinancement: string | null;
        orderwebFinancementMode: string | null;
        orderwebFinancementRefus: string | null;
        orderwebFinancementSigned: string | null;
        orderwebFinancementValid: string | null;
        orderwebGravage: string | null;
        orderwebGravagePac: string | null;
        orderwebItesoftId: string | null;
        orderwebLivraison: string | null;
        orderwebLivraisonDefault: string | null;
        orderwebLivraisonId: string | null;
        orderwebLivraisonXInitiale: string | null;
        orderwebMarquageId: string | null;
        orderwebOrderCode: string | null;
        orderwebOrderId: string | null;
        orderwebOrigin: string | null;
        orderwebPayment: string | null;
        orderwebPrice: string | null;
        orderwebPvoproduit: string | null;
        orderwebPvoschema: string | null;
        orderwebRefdealershipid: string | null;
        orderwebRefund: string | null;
        orderwebReprise: string | null;
        orderwebSiteid: string | null;
        orderwebSolde: string | null;
        orderwebSoldeDate: string | null;
        orderwebSoldeValidBy: string | null;
        orderwebStatus: string | null;
        orderwebStatusId: string | null;
        orderwebStockIdentifiant: string | null;
        orderwebTotal: string | null;
        orderwebVNumpoli: string | null;
        orderwebVehiculeidentifiant: string | null;
        orderwebXInitiale: string | null;
        orderwebContact: string | null;
        orderwebStatusDescription: string | null;
        orderwebStatusLibelle: string | null;
    };
    funding: {
        fundingProcessLabel: string | null;
        fundingProductType: string | null;
        fundingCategory: string | null;
        fundingPartnerName: string | null;
        fundingPartnerCode: string | null;
        fundingContractNumber: string | null;
        fundingDuration: string | null;
        fundingPrice: string | null;
        fundingPrestation: string | null;
        fundingStatus: string | null;
        fundingSellerId: string | null;
        fundingSellerLastName: string | null;
        fundingSellerFirstName: string | null;
        fundingEligibleProduction: string | null;
        fundingTegRate: string | null;
        fundingFinanciallyAccounted: string | null;
        fundingPrestations: {
            fundingPrestationBenefitCode: string | null;
            fundingPrestationAmount: string | null;
        }[];
        refDealershipLegalname: string | null;
        fundingCreatedDate: string | null;
    };
    icare: {
        maintenanceContractNomCommercialFormule: string | null;
        maintenanceSrcApplicationName: string | null;
        maintenanceContractNumeroContract: string | null;
        maintenantSrcProcessMoment: string | null;
        maintenanceContractDureeCouvertureContract: string | null;
        maintenanceContractStatutContract: string | null;
        maintenanceContractIdentifiantTiersDistributeur: string | null;
        maintenanceRefDealershipLegalname: string | null;
        maintenanceContractPrenomCommercialPerso: string | null;
        maintenanceContractNomCommercialPerso: string | null;
        maintenanceContractCodeVendeurContract: string | null;
    };
    tscan: {
        tscanId: string | null;
        positionAxle: string | null;
        positionIndex: string | null;
        recommandation: string | null;
        fileLink: string | null;
        tires: {
            positionAxle: string | null;
            positionIndex: string | null;
            recommandation: string | null;
        }[];
    };
    prestationApvDMS: {
        tiNumber: string | null;
        userInfo: string | null;
        interventionStartDate: string | null;
        interventionEndDate: string | null;
        review: string | null;
        interventionPriceWorkforce: string | null;
        interventionPricePiece: string | null;
        interventionPriceWithTaxes: string | null;
        taxes: string | null;
        paymentMode: string | null;
        paymentType: string | null;
        dealership: string | null;
        lastMileageKnown: string | null;
    };
    mecaplanning: {
        processLabel: string | null;
        srcProcessMoment: string | null;
        refDealershipId: string | null;
        refDealershipLegalname: string | null;
        workfilerdvid: string | null;
        workfilebookedonline: string | null;
        workfilestart: string | null;
        workfilefinish: string | null;
        workfilemainwork: string | null;
        workfilecomment: string | null;
        workfileloaner: string | null;
        workshopid: string | null;
        workshopname: string | null;
        receivingid: string | null;
        receivingname: string | null;
    };
    fleetback: {
        auditNameModel: string | null;
        auditDescriptionModel: string | null;
        auditReference: string | null;
        auditStatusIntervention: string | null;
        auditLinkAudit: string | null;
        auditIdIntervention: string | null;
        auditPreview: string | null;
        auditCreatedbyId: string | null;
        auditCreatedbyEmail: string | null;
        auditCreatedbyFirstname: string | null;
        auditCreatedbyLastname: string | null;
    };
    cirano: {
        nameGtie: string | null;
        typeGite: string | null;
        stateGite: string | null;
        startDate: string | null;
        endDate: string | null;
        pdvContributor: string | null;
        eventName: string | null;
        srcProcessId: string | null;
        libFamily: string | null;
        libProduct: string | null;
    };
    adictiz: {
        dotation: string | null;
        validationDuration: string | null;
        marketingCampaignName: string | null;
        srcProcessId: string | null;
    };
    opteven: {
        guarantyStatus: string | null;
        referenceExtern: string | null;
        program: string | null;
        srcProcessId: string | null;
        numberGuarantee: string | null;
        guaranteeCreationDate: string | null;
        product: {
            startDate: string | null;
            endDate: string | null;
            catProduct: string | null;
            product: string | null;
            libStat: string | null;
            produitLibtech: string | null;
            productStatLevelCoverage: string | null;
            libFamily: string | null;
            durationRisk: string | null;
            productTypeRegement: string | null;
        }[];
    };

    commercialProposal: CommercialProposal;
    tradeInOffer: TradeInOffer;
}

export interface Prestation {
    type: string;
    slug: string;
    name: string;
    description: string;
    price: number;
}

export enum FinancialTypes {
    LOA = 'loa',
    BALLON = 'credit_ballon',
    LLD = 'lld',
    CREDIT_BAIL = 'credit_bail',
}

export interface LeadDetails {
    project: {
        customerProject: string;
        vehicles: Array<Partial<Vehicle>>;
    };
    partExchange: unknown;
    finance: {
        id: number;
        financialType: FinancialTypes;
        financialAgency: null;
        financialBudget: number;
        financialRent: number;
        financialInput: number;
        financingAgencyLeadWon: string;
        status: string | null;
        interest: string | null;
        comment: string | null;
        deadline: string | null;
        contractNumber: string;
        contractStartDate: string;
        contractEndDate: string;
        residualValue: number;
        autofidFileNumber: string | null;
        contractExtensionDate: string | null;
        maximumContractExtensionDate: string | null;
        isCredipar: boolean | null;
        isResidualBlocked: boolean;
    };
    informations: LeadDetailsInformations;
    events: EventDetails[];
    prestations: Prestation[];
}

export enum FollowUpTaskStatus {
    CLOSED = 'follow_up_task_status_closed',
    OPEN = 'follow_up_task_status_open',
    CLOSED_CONFIRMED = 'follow_up_task_status_closed_confirmed',
}

export interface LeadReason {
    id: number;
    name: string;
    slug: string;
    value: string | null;
    leadReasons: LeadReason[];
    created: string;
    updated: string;
}
export interface ApiLeadReason {
    [key: string]: LeadReason;
}

interface LeadListingParam {
    id: number;
    name: string;
    slug: string;
    isActive: boolean;
    listingParams: LeadListingParam[];
    value: string | null;
    created: string;
    updated: string;
}
export interface ApiLeadListingParam {
    [key: string]: LeadListingParam;
}

export interface EventDetails {
    date: string;
    user: {
        userId: number;
        firstname: string;
        lastname: string;
        role: string;
        email: string;
        mobile: string;
    };
    action: {
        id: number;
        name: string;
        source: string;
        highPriority?: boolean;
        status: {
            name: string;
            subName: null;
            slug: string;
            previous: {
                name: string;
                subName: null;
                slug: string;
            };
        };
        scheduleAppointment: {
            id: null;
            subject: null;
            comment: null;
            isPhysical: null;
            isHomeTrial: null;
            result: null;
            dateStart: null;
            dateEnd: null;
            created: null;
            updated: null;
        };
        message: string;
    };
    event: {
        id: number;
    };
    eventType: {
        slug: string;
    };
    reason: {
        mainReasonName: null;
        mainReasonSlug: null;
        subReasonName: null;
        subReasonSlug: null;
    };
}

export type ApiCustomerVehicle = CustomerVehicle;
export interface CustomerVehicle {
    bodyType: string | null;
    brand: string | null;
    color: string | null;
    energy: string | null;
    gearBox: string | null;
    image: string | null;
    lastActivityBetweenThem: string | null;
    lastMileageKnown: number | string | null;
    model: string | null;
    registrationNumber: string | null;
    registrationNumberDate: string | null;
    sellingPrice: number | string | null;
    vehicleUuid: string | null;
    version: string | null;
    vin: string | null;
    events: CustomerEvent[];
}
export interface CustomerEvent {
    actionId: number | null;
    bodyType: string | null;
    brand: string | null;
    color: string | null;
    emailQueueAttachments: string | null;
    emailQueueContent: string | null;
    emailQueueId: string | null;
    emailQueueParams: string | null;
    energy: string | null;
    eventCreated: string | null;
    eventDate: string | null;
    eventId: number | null;
    eventName: string | null;
    eventRawFirstname: string | null;
    eventRawLastname: string | null;
    eventTypeSlug: string | null;
    eventUserFirstname: string | null;
    eventUserLastname: string | null;
    lastMileageKnown: number | string | null;
    leadDealershipName: string | null;
    dealershipSource: string | null;
    leadElementsID: string | null;
    leadId: number | null;
    leadParentId: string | null;
    leadReasonName: string | null;
    leadReasonSlug: string | null;
    leadReceipted: string | null;
    leadSourceName: string | null;
    leadSourceSlug: string | null;
    leadStatusName: string | null;
    leadStatusNameHisto: string | null;
    leadStatusSlug: string | null;
    leadStatusSlugHisto: string | null;
    leadStatusSubName: string | null;
    leadStatusSubNameHisto: string | null;
    leadStatusUpdated: string | null;
    leadTypeName: string | null;
    leadTypeSlug: string | null;
    model: string | null;
    registrationNumber: string | null;
    registrationNumberDate: string | null;
    scheduleAppointmentDateEnd: string | null;
    scheduleAppointmentDateStart: string | null;
    scheduleAppointmentId: number | null;
    sellingPrice: number | null;
    snapshotContactFirstname: string | null;
    snapshotContactLastname: string | null;
    vehicleId: string | null;
    vehicleUuid: string | null;
    version: string | null;
    vin: string | null;
    srcApplicationName?: string | null;
    processType?: string | null;
    apvVehicleMake?: string | null;
    apvVehicleModel?: string | null;
    scServiceResumeProductLabel?: string | null;
    scDisasterProductLabel?: string | null;
    scRenewalProductLabel?: string | null;
    scTerminationProductLabel?: string | null;
    maintenanceContractNomCommercialFormule?: string | null;
    auditNameModel?: string | null;
    processCategory?: string | null;
    fundingProductType?: string | null;
    fundingPrice?: string | null;
    fundingPartnerName?: string | null;
    fundingSellerFirstName?: string | null;
    fundingSellerLastName?: string | null;
    scServiceResumeSellingPriceWithTaxes?: string | null;
    appointmentOrderPaymentAmount?: string | null;
    orderwebTotal?: string | null;
    leadVehPrice?: string | null;
    sellingPriceWithTaxes?: string | null;
    interventionPriceWithTaxes?: string | null;
    maintenanceContractCodeVendeurContract?: string | null;
    scServiceResumeSellerFirstName?: string | null;
    scServiceResumeSellerLastName?: string | null;
    scDisasterUserInfo?: string | null;
    scRenewalContractId?: string | null;
    worktechcode?: string | null;
    auditCreatedbyFirstname?: string | null;
    auditCreatedbyLastname?: string | null;
    maintenanceContractStatutContract?: string | null;
    orderwebStatusLibelle?: string | null;
    scTerminationTerminationReasonSimple?: string | null;
    appointmentAppointmentDateStart?: string | null;
    appointmentOrderStatusSlug?: string | null;
    appointmentOrderStatusName?: string | null;
    leadClosLibel?: string | null;
    auditStatusIntervention?: string | null;
    endDate?: string | null;
    libProduct?: string | null;
    typeGite?: string | null;
    pdvContributor?: string | null;
    firstEventOfTheDay?: boolean;
    program?: string | null;
    isVcuEvent: boolean;

    orderId?: string | null;
    orderStatus?: string | null;
    offerId?: string | null;
    offerStatus?: string | null;
    affectedUserLabel?: string | null;
    tradeinVehicleNegotiatedPriceValue?: number | null;
    soldVehicleNegotiatedPriceValue?: number | null;
    orderTradeinVehicleNegotiatedPriceValue?: number | null;
    orderSoldVehicleNegotiatedPriceValue?: number | null;

    commercialProposal?: CommercialProposal | null;
    tradeInOffer?: TradeInOffer | null;
}

interface ActivityStatus {
    name: string;
    subName: string;
    slug: string;
    previous?: ActivityStatus;
}
interface CustomerActivity {
    id: number;
    created: string;
    user: Pick<Customer, 'id' | 'firstname' | 'lastname' | 'role' | 'mobile' | 'email'> & {
        dealershipId: string;
    };
    type: {
        name: string;
        slug: string;
    };
    source: {
        name: string;
        slug: string;
    };
    status: ActivityStatus;
    action: {
        scheduleAppointment: {
            subject: string;
            comment: string;
            isPhysical: true;
            isHomeTrial: false;
            result: null;
            dateStart: string;
            dateEnd: string;
        };
        vehicles: Vehicle[];
    };
}

export type ApiCustomerActivities = CustomerActivity[];

export interface CommercialProposal {
    order: boolean;
    id?: string | null;
    number?: string | null;
    status?: string | null;
    startDate?: Dayjs | null;
    endDate?: Dayjs | null;
    affectedUserLabel?: string | null;
    comment?: string | null;
    acceptanceDate?: Dayjs | null;
    cancellationDate?: Dayjs | null;
    cancellationCause?: string | null;
    price?: string | null;
    closedDate?: Dayjs | null;
    orderDeliveryDate?: Dayjs | null;
    upsales?:
        | {
              label?: string | null;
              value?: string | null;
          }[]
        | null;
}

export interface TradeInOffer extends CommercialProposal {}

export interface EventDetailCommercialProposalType {
    informations: Informations;
    commercialProposal: CommercialProposal;
}

export interface EventDetailTradeInOfferType {
    informations: Informations;
    tradeInOffer: TradeInOffer;
}

export type CreateSalesforceLead = {
    origin_id: string;
};

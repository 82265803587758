import { TradeInOffer } from '@app/crud/customers/customer.type';
import StatusLead from '@app/pages/customers/Partials/StatusLead';
import React from 'react';
import { useIntl } from 'react-intl';

type Props = {
    tradeInOffer?: TradeInOffer;
};

const TradeInOfferStatus = ({ tradeInOffer }: Props) => {
    const Intl = useIntl();

    if (!tradeInOffer?.status) {
        return <span>-</span>;
    }

    let statusType;
    switch (tradeInOffer?.status) {
        case '0':
            statusType = 'primary';
            break;
        case '1':
            statusType = 'success';
            break;
        default:
            statusType = 'secondary';
            break;
    }

    const status = `CUSTOMER.VIEW.LIFE.DETAIL.PLANET_VO.${tradeInOffer?.order ? 'ORDER' : 'OFFER'}.STATUS.${
        tradeInOffer?.status
    }`;
    return <StatusLead overridedStatus={statusType} statusName={Intl.formatMessage({ id: status })} />;
};

export default TradeInOfferStatus;

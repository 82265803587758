import { difference } from 'lodash';
import { Chip } from 'primereact/chip';
import { MultiSelect } from 'primereact/multiselect';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

export type DataTableMultiSelectOption<T> = {
    key: T;
    label: string;
};

export type DataTableMultiSelectProps<T> = {
    name: string;
    values: Array<T>;
    options: Array<DataTableMultiSelectOption<T>>;
    onChange?: (value: Array<T>) => void;
    lazyScroll?: boolean;
};

const DataTableMultiSelect = ({
    name,
    values,
    options,
    onChange,
    lazyScroll = false,
}: DataTableMultiSelectProps<string | number>) => {
    const [selectedItems, setSelectedItems] = useState([]);

    useEffect(() => {
        const newValues = values?.filter((key) => options.some((option) => option.key === key));
        if (difference(values, newValues)?.length > 0) {
            onChange(newValues);
        }
        setSelectedItems(newValues);
    }, [values]);

    const Intl = useIntl();

    const selectedItemTemplate = (key: string | number) => {
        let label = options.find((option) => option.key === key)?.label;

        if (selectedItems?.length > 1) {
            if (selectedItems.findIndex((selectedItem) => selectedItem === key) !== 0) {
                // we should return a span instead of null to avoid to display the "empty" default display
                return <span />;
            }
            label = `${selectedItems.length} ${Intl.formatMessage({ id: 'LEADS_ONLINE.TABLE.SELECTED_ITEMS' })}`;
        }

        if (!label) {
            // we should return a span instead of null to avoid to display the "empty" default display
            return <span />;
        }

        return <Chip label={label} className="font-weight-bold p-chip-light-grey-secondary text-white" />;
    };

    return (
        <MultiSelect
            name={name}
            value={selectedItems}
            options={options}
            optionLabel="label"
            optionValue="key"
            onChange={(e) => {
                setSelectedItems(e.value);
                onChange?.(e.value);
            }}
            filter
            showClear
            virtualScrollerOptions={
                lazyScroll && options.length > 30 ? { itemSize: 30, scrollHeight: '200px', delay: 20 } : undefined
            }
            selectedItemTemplate={selectedItemTemplate}
            className="datatable-multiselect"
        />
    );
};

export default DataTableMultiSelect;

import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';

import { showDetailEvent } from '@app/pages/customers/Helpers/Events';
import { actions as modalActions } from '@app/store/modal/modal.store';
import { Lead } from '@app/store/customers/customers.store';

import toast from '@app/partials/content/Toast';

import useAction from './useAction';
import { useAppSelector } from '@app/hooks';
import useActionClient from './useActionClient';
import TakeLeadClient from '@app/crud/action/take-lead';

const useActionHandler = (lead: Lead) => {
    const dispatch = useDispatch();
    const Intl = useIntl();
    const { data: customer } = useAppSelector((state) => state.customer.customer);
    const [_treatSeparatelyLoading, treatSeparately] = useAction({
        type: 'treat_separately',
        success: () => {
            toast({
                variant: 'success',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.TREAT_SEPARATELY.SUCCESS' }),
            });
        },
        failed: () => {
            toast({
                variant: 'danger',
                message: Intl.formatMessage({ id: 'CUSTOMERS.MODAL.TREAT_SEPARATELY.ERROR' }),
            });
        },
    });

    const [caller] = useActionClient(new TakeLeadClient(), {
        notifySucess: Intl.formatMessage({ id: 'LEADS_ONLINE.TAKE_LEAD.SUCCESS' }),
        notifyFail: Intl.formatMessage({ id: 'LEADS_ONLINE.TAKE_LEAD.FAILED' }),
    });

    const takeLead = () => {
        caller({
            lead: lead?.id,
        }).catch((reason) => {
            console.error(reason);
        });
    };

    return (action: string, params?: any) => {
        if (params?.force) {
            dispatch(
                modalActions.modalChange('force_treatment', {
                    lead,
                    action,
                }),
            );
            return;
        }

        switch (action) {
            case 'consign':
                dispatch(
                    modalActions.modalChange('consign', {
                        leadId: lead.id,
                    }),
                );
                break;
            case 'show_details': {
                const showDetailButton = document.querySelector(`#event-all-${lead?.id}`);
                showDetailEvent(showDetailButton, true);
                break;
            }
            case 'link_lead':
                dispatch(modalActions.modalChange('link_lead'));
                break;
            case 'treat_separately':
                Swal.fire({
                    title: Intl.formatMessage({ id: 'CUSTOMER.MODAL.TREAT_SEPARATELY.TITLE' }),
                    text: Intl.formatMessage({ id: 'CUSTOMER.MODAL.TREAT_SEPARATELY.TEXT' }),
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: Intl.formatMessage({ id: 'TRANSLATOR.YES' }),
                    cancelButtonText: Intl.formatMessage({ id: 'TRANSLATOR.NO' }),
                    customClass: {
                        confirmButton: 'btn mr-3 btn-primary',
                        cancelButton: 'btn btn-secondary',
                    },
                    buttonsStyling: false,
                    preConfirm: () => {
                        Swal.showLoading();
                        return treatSeparately({
                            leadIds: customer.leads.map((leadData) => leadData.id),
                        });
                    },
                }).then(() => {});
                break;
            case 'local_apv':
                dispatch(
                    modalActions.modalChange('local_apv', {
                        leadId: lead.id,
                    }),
                );
                break;
            case 'local_commerce':
                dispatch(
                    modalActions.modalChange('local_commerce', {
                        leadId: lead.id,
                    }),
                );
                break;
            case 'next_action':
                dispatch(
                    modalActions.modalChange('next_action', {
                        leadId: lead.id,
                    }),
                );
                break;
            case 'reply_local_apv':
                dispatch(
                    modalActions.modalChange('reply_local_apv', {
                        leadId: lead.id,
                    }),
                );
                break;
            case 'close_local_apv':
                dispatch(
                    modalActions.modalChange('close_local_apv', {
                        leadId: lead.id,
                    }),
                );
                break;
            case 'reopen_local_apv':
                dispatch(
                    modalActions.modalChange('reopen_local_apv', {
                        leadId: lead.id,
                    }),
                );
                break;
            case 'take':
                takeLead();
                break;
            case 'assign':
                dispatch(
                    modalActions.modalChange('assign_lead', {
                        ...params,
                        leadId: lead?.id,
                    }),
                );
                break;
            case 'follow_up_task':
                dispatch(
                    modalActions.modalChange('follow_up_task', {
                        leadId: lead.id,
                        isLockAction: true,
                    }),
                );
                break;
            default:
                break;
        }
    };
};

export default useActionHandler;

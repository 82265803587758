import React, { useEffect, useState } from 'react';
import { Card, Row, Tab } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router-dom';

import useFilters from '@app/hooks/useFilters';
import useTableHandlers from '@app/hooks/useTableHandlers';

import { getSort } from '@app/helpers/DataTableHelper';
import { routeTo } from '@app/helpers/RoutesHelper';

import { getLeadByStatus } from '@app/crud/customers/customer.crud';

import LeadOnlineDataTable from '@app/pages/leadsOnline/LeadOnlineDataTable';
import ROUTES from '@app/router/Routes';

import FiltersProvider from '@app/partials/content/FiltersProvider';
import RefreshTable from '@app/partials/content/RefreshTable';
import { Filters } from '@app/partials/layout/Filters';
import PortletTab from '@app/partials/layout/PortletTab';

const counters = {
    all: 'lead_all',
    new: 'lead_new',
    'action-to-do': 'lead_action_to_do',
    closed: 'lead_in_progress',
};

const tabs = [
    {
        status: 'all',
        counter: 'lead_all',
        title: <FormattedMessage id="APV.CONTACT_LEAD.TABS.ALL" />,
    },
    {
        status: 'new',
        counter: 'lead_new',
        title: <FormattedMessage id="APV.CONTACT_LEAD.TABS.NEW" />,
    },
    {
        status: 'action_to_do',
        counter: 'action_to_do',
        title: <FormattedMessage id="APV.CONTACT_LEAD.TABS.IN_PROGRESS" />,
    },
    {
        status: 'close',
        counter: 'lead_close',
        title: <FormattedMessage id="APV.CONTACT_LEAD.TABS.CLOSED" />,
    },
];

const APVLeads = () => {
    const { statusType = 'all' } = useParams();
    const [activeKey, setActiveKey] = useState(statusType);
    const { items, start, filters, sortField, sortOrder } = useFilters({
        name: `leads_apv_${statusType}`,
    });
    const { data, handleFilter, handlePagination, handleSort, refresh, loading } = useTableHandlers({
        filterStore: `leads_apv_${statusType}`,
        fetchAction: getLeadByStatus,
        counterName: counters[statusType],
        withStore: false,
        paramsInterceptor: (params, filtersStore) => {
            const sort = getSort(filtersStore?.sortField, filtersStore?.sortOrder, 'created', 'desc');
            return {
                ...params,
                page: filtersStore.page,
                limit: filtersStore.items,
                sort: `${sort?.field}_${sort?.order}`,
                leadType: 'local_apv',
                isLeadTypeRestricted: true,
            };
        },
    });

    useEffect(() => {
        setActiveKey(statusType || 'all');
    }, [statusType, setActiveKey]);

    useEffect(() => {
        handleFilter({
            status: {
                value: statusType.replaceAll('-', '_'),
            },
        });
    }, [handleFilter, activeKey, statusType]);

    useEffect(() => {
        if (!Object.values(tabs).some((item) => item.status === activeKey)) {
            setActiveKey('all');
        }
    }, [activeKey, data.counter]);

    return (
        <Card className="p-4 position-relative">
            <FiltersProvider value={filters}>
                <PortletTab
                    defaultActiveKey={activeKey}
                    activeKey={activeKey}
                    id={activeKey}
                    onClick={setActiveKey}
                    redirects={{
                        all: routeTo(ROUTES.APV_LEADS.PATH, {
                            statusType: 'all',
                        }),
                        new: routeTo(ROUTES.APV_LEADS.PATH, {
                            statusType: 'new',
                        }),
                        action_to_do: routeTo(ROUTES.APV_LEADS.PATH, {
                            statusType: 'action_to_do',
                        }),
                        close: routeTo(ROUTES.APV_LEADS.PATH, {
                            statusType: 'close',
                        }),
                    }}
                >
                    {Object.values(tabs).map((item) => (
                        <Tab eventKey={item.status} title={item.title}>
                            {activeKey === item.status && (
                                <>
                                    <Filters>
                                        <Row>
                                            <RefreshTable count={data.count} onClick={refresh} />
                                        </Row>
                                    </Filters>
                                    <LeadOnlineDataTable
                                        statusType={statusType}
                                        items={items}
                                        start={start}
                                        filters={filters}
                                        sortField={sortField}
                                        sortOrder={sortOrder}
                                        data={data}
                                        loading={loading}
                                        handleFilter={handleFilter}
                                        handlePagination={handlePagination}
                                        handleSort={handleSort}
                                        actionPermissions={['APV_LOCAL_TAKE_LEAD_CLIENT']}
                                        showClientProject
                                    />
                                </>
                            )}
                        </Tab>
                    ))}
                </PortletTab>
            </FiltersProvider>
        </Card>
    );
};

export default APVLeads;

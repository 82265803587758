import React, { useContext } from 'react';
import { Card } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import Comment from '@app/pages/customers/Partials/Comment';
import { actions } from '@app/store/modal/modal.store';

import { EventDetailContext } from './EventDetailProvider';
import useActionClient from '../../Hooks/useActionClient';
import UpdateCommentClient from '@app/crud/action/update-comment';

type Props = {
    event: any;
    typeSlug: string;
    isLeadClosed: boolean;
};

const EventDetailDealershipNote = ({ event, typeSlug, isLeadClosed }: Props) => {
    const { fetchData } = useContext(EventDetailContext);
    const dispatch = useDispatch();
    const Intl = useIntl();

    const [caller] = useActionClient(new UpdateCommentClient(), {
        notifySucess: Intl.formatMessage({ id: 'TRANSLATOR.SUCCESS' }),
        notifyFail: Intl.formatMessage({ id: 'TRANSLATOR.ERROR' }),
    });

    const updateProjectComment = (data: { comment: string }) => {
        caller({
            lead: event?.informations.identifier,
            comment: data.comment,
        }).then(() => {
            dispatch(actions.closeModals());
            fetchData(event?.informations.identifier);
        });
    };

    return (
        <Card className="card--shadowed">
            <Card.Header className={`event__origin--${typeSlug} position-relative`}>
                <div className={`event__origin-bg--${typeSlug} pin`}>
                    <i className="la la-building text-white la-lg" />
                </div>
                <span className="ml-4">
                    {Intl.formatMessage({ id: 'CUSTOMER.VIEW.LIFE.DETAIL.DEALERSHIP_NOTE.TITLE' })}
                </span>
            </Card.Header>
            <Card.Body className="position-relative d-flex flex-column">
                <Comment
                    comment={event?.informations?.comment}
                    callback={updateProjectComment}
                    label="COMMENT_LEAD"
                    withTags
                    lead={event?.informations}
                    readOnly={!event?.informations?.identifier || event?.informations?.processType || isLeadClosed}
                />
            </Card.Body>
        </Card>
    );
};

export default EventDetailDealershipNote;
